import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';



const data = [
  {
    link: 'https://github.com/gregoriosaggese',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.linkedin.com/in/gregorio-saggese-5334b8186',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  }
];

export default data;
