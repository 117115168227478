import React from "react";
import { Link } from "react-router-dom";
import ContactIcons from "../Contact/ContactIcons";
const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/FotoProfilo.png`} alt="" />
      </Link>
      <header>
        <h2>Gregorio Saggese</h2>
        <p>
          <a href="mailto:saggesegregorio@gmail.com">
            saggesegregorio@gmail.com
          </a>
        </p>
        <Link to="/cert">
          <img src={`${PUBLIC_URL}/images/dva01.png`} alt="" />
        </Link>
      </header>
    </section>
    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Gregorio Saggese | Powered by <a style={{color:"orange"}} href="https://aws.amazon.com/it/amplify/getting-started/">AWS Amplify</a>
      </p>
    </section>
  </section>
);

export default SideBar;
