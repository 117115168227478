import React from "react";
import { Link } from "react-router-dom";
import Hamburger from "./Hamburger";
import routes from "../../data/routes";
import { Button } from "@aws-amplify/ui-react";
import { Amplify, Storage, API, Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";

Amplify.configure(awsconfig);
const ID = "/V1/DOWNLOADS/API";

// Websites Navbar, displays routes defined in 'src/data/routes'
const Navigation = () => (
  <header id="header">
    <h1 className="index-link">
      {routes
        .filter((l) => l.index)
        .map((l) => (
          <Link key={l.label} to={l.path}>
            {l.label}
          </Link>
        ))}
    </h1>
    <nav className="links">
      <ul>
        {routes
          .filter((l) => !l.index)
          .map((l) => {
            if (l.label === "Download my CV") {
              return (
                <li key={l.label}>
                  <Button
                    className=".links ui li a"
                    id="#header"
                    style={{
                      boxShadow: "none",

                      fontFamily: "Raleway Helvetica sans-serif",
                      fontSize: "0.7em",
                      fontWeight: 400,
                      letterSpacing: "0.25em",
                      textTransform: "uppercase",
                    }}
                    onClick={download}
                  >
                    {l.label}
                  </Button>
                </li>
              );
            }
            
            return (
              <li key={l.label}>
                <Link target={"_blank"} to={l.path}>{l.label}</Link>
              </li>
            );
          })}
      </ul>
    </nav>
    <Hamburger />
  </header>
);

async function download() {
  let body = {
    id: ID,
    num_downloads: 1,
  };
  getUser().then((res) => {
    if (res !== undefined) {
      API.put("visitors", "/v1/downloads", {
        body: body,
      }).then(async (res) => {
        const result = await Storage.get("CV_Gregorio_Saggese_2023_V1.pdf", {
          level: "public",
          bucket:
            "saggesegregorio-cv-certifications" +
            process.env.REACT_APP_ID_VALUE +
            "-" +
            process.env.REACT_APP_USER_BRANCH,
          region: "eu-central-1",
        });
        fetch(result).then((response) => {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "CV_Gregorio_Saggese_2023_V1.pdf";
            a.click();
          });
        });
      });
    }
  })



  
}

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((userData) => {
      return userData;
    })
    .catch(() => {
      return (
        alert("Login is required")
      );
    });
}
export default Navigation;
