import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "./layouts/Main"; // fallback for lazy pages
import "./static/css/main.scss"; // All of our styles
import { Amplify, API } from "aws-amplify";
import awsconfig from "./aws-exports";
import { v4 as uuidv4 } from "uuid";

Amplify.configure(awsconfig);

const { PUBLIC_URL } = process.env;
const Login = lazy(() => import("./pages/Login"));
const Changelog = lazy(() => import("./pages/Changelog"));
const Api = lazy(() => import("./pages/Apis"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Index = lazy(() => import("./pages/Index"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Resume = lazy(() => import("./pages/Resume")); //resume is skill section
const CertificationsPage = lazy(() => import("./pages/CertificationsPage"));

const App = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Access count value from session storage
    let pageView = sessionStorage.getItem("pageView");
    if (pageView === null) {
      // Initialize page views count
      pageView = 1;
    } else {
      // Increment count
      pageView = Number(pageView) + 1;
    }
    // Update session storage
    sessionStorage.setItem("pageView", pageView);
    setCount(pageView);
  }, []); //No dependency to trigger in each page load
  if (count === 1) {
    const uuid = uuidv4();
    API.post("visitors", "/v1/visitors", {
      headers: {}, // OPTIONAL
      body: { id: uuid },
    });
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Suspense fallback={<Main />}>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/about" component={About} />
          <Route path="/cert" component={CertificationsPage} />
          <Route path="/contact" component={Contact} />
          <Route path="/resume" component={Resume} />
          <Route path="/login" component={Login} />
          <Route path="/changelog" component={Changelog} />
          <Route path="/api" component={Api} />
          <Route component={NotFound} status={404} />
          
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
