const routes = [
  {
    index: true,
    label: "Home",
    path: "/",
  },
  {
    label: "Certifications",
    path: "/cert",
  },
  {
    label: "Skills",
    path: "/resume",
  },
  {
    label: "About",
    path: "/about",
  },
  {
    label: "Contact",
    path: "/contact",
  },
  {
    label: "Changelog",
    path: "/changelog",
  },
  {
    label: "Login",
    path: "/api",
  },
  {
    label: "Download my CV",
    path: "/",
  },
];

export default routes;
