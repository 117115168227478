import React, { Suspense, lazy, useState } from "react";
import { Amplify, Storage, API, Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import routes from "../../data/routes";
import { Button } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

Amplify.configure(awsconfig);

const Menu = lazy(() => import("react-burger-menu/lib/menus/slide"));
const ID = "/V1/DOWNLOADS/API";
const Hamburger = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="hamburger-container">
      <nav className="main" id="hambuger-nav">
        <ul>
          {open ? (
            <li className="menu close-menu">
              <div onClick={() => setOpen(!open)} className="menu-hover">
                &#10005;
              </div>
            </li>
          ) : (
            <li className="menu open-menu">
              <div onClick={() => setOpen(!open)} className="menu-hover">
                &#9776;
              </div>
            </li>
          )}
        </ul>
      </nav>
      <Suspense fallback={<></>}>
        <Menu right isOpen={open}>
          <ul className="hamburger-ul">
            {routes.map((l) => {
              if (l.label === "Download my CV") {
                return (
                  <li key={l.label}>
                    <Button
                      style={{
                        boxShadow: "none",
                        padding: 0,
                        marginTop: "20px",
                      }}
                      onClick={download}
                    >
                      {l.label}
                    </Button>
                  </li>
                );
              }
              return (
                <li key={l.label}>
                  <Link to={l.path} onClick={() => setOpen(!open)}>
                    <h3 className={l.index && "index-li"}>{l.label}</h3>
                  </Link>
                </li>
              );
            })}
          </ul>
        </Menu>
      </Suspense>
    </div>
  );
};

async function download() {
  let body = {
    id: ID,
    num_downloads: 1,
  };

  getUser().then((res) => {
    if (res !== undefined) {
      API.put("visitors", "/v1/downloads", {
        body: body,
      }).then(async (res) => {
        const result = await Storage.get("CV_Gregorio_Saggese_2023_V1.pdf", {
          level: "public",
          bucket:
            "saggesegregorio-cv-certifications" +
            process.env.REACT_APP_ID_VALUE +
            "-" +
            process.env.REACT_APP_USER_BRANCH,
          region: "eu-central-1",
        });
        fetch(result).then((response) => {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "CV_Gregorio_Saggese_2023_V1.pdf";
            a.click();
          });
        });
      });
    }
  });
}

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((userData) => {
      return userData;
    })
    .catch(() => {
      return alert("Login is required");
    });
}
export default Hamburger;
